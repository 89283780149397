import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import NetInfo from "@react-native-community/netinfo";
import AsyncStorage from "@react-native-async-storage/async-storage";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isConnected: boolean | null;
  offlineQueue: Message[];
  token: string;
  offlineState: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface IOfflineBrowsingContext {
  // Customizable Area Start
  isConnected: boolean | null;
  offlineQueue: Message[];
  offlineState: any;
  sendNetworkSafeApiRequest: (
    uniqueApiCallId: string,
    method: string,
    endpoint: string,
    headers?: any,
    body?: any
  ) => void;
  setNetworkSafeState: (key: string, state: any) => void;
  getNetworkSafeState: (key: string) => Promise<any>;
  // Customizable Area End
}

export default class OfflineBrowsingProviderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isConnected: null,
      offlineQueue: [],
      token: "",
      offlineState: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  setNetworkSafeState = (key: string, state: any) => {
    const newOfflineState = {
      ...this.state.offlineState,
      [key]: state,
    };

    AsyncStorage.setItem("OfflineState", JSON.stringify(newOfflineState));
    this.setState({ offlineState: newOfflineState });
    this.showAlert("Saved offline state", "");
  };

  getNetworkSafeState = async (key: string) => {
    const offlineState = this.state.offlineState
      ? this.state.offlineState
      : await AsyncStorage.getItem("OfflineState");
    const requestedState = offlineState[key];
    return requestedState;
  };

  async componentDidMount() {
    this.getToken();
    const storedOfflineQueue = this.isPlatformWeb()
      ? await localStorage.getItem("OfflineQueue")
      : await AsyncStorage.getItem("OfflineQueue");

    if (storedOfflineQueue) {
      const networkMessageList = JSON.parse(storedOfflineQueue);
      this.setState({ offlineQueue: networkMessageList });

      if (this.state.isConnected && networkMessageList.length > 0) {
        const firstMessage = networkMessageList[0];
        runEngine.sendMessage(firstMessage.id, firstMessage);
      }
    }
    const unsubscribe = NetInfo.addEventListener((state) => {
      // If the internet connection has been restored
      // and there are messages on the OfflineQueue
      const { offlineQueue } = this.state;
      if (
        !this.state.isConnected &&
        state.isConnected &&
        offlineQueue.length > 0
      ) {
        const networkMessage = offlineQueue[0];
        if (!networkMessage) {
          return;
        }
        runEngine.sendMessage(networkMessage.id, networkMessage);
      }
      this.setState({ isConnected: state.isConnected });
    });
    this.unsubscribe = unsubscribe;
  }

  async componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    console.log('!!!update', { isConnected: this.state.isConnected })
  }

  unsubscribe = () => { };

  //callback until queue has been empty when connection restored
  sendNextOfflineQueueMessage = () => {
    const { offlineQueue } = this.state;

    if (offlineQueue.length === 0) {
      return;
    }

    const networkMessage = offlineQueue[0];
    runEngine.sendMessage(networkMessage.id, networkMessage);
  };

  updateOfflineMessageQueue = (offlineQueue: Message[]) => {
    AsyncStorage.setItem("OfflineQueue", JSON.stringify(offlineQueue));
    this.setState({ offlineQueue });
  };

  sendNetworkSafeApiRequest = (
    uniqueApiCallId: string,
    method: string,
    endpoint: string,
    headers?: any,
    body?: any
  ) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.messageId = uniqueApiCallId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    if (headers) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
    }

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }

    if (this.state.isConnected) {
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.updateOfflineMessageQueue([
        ...this.state.offlineQueue,
        requestMessage,
      ]);
    }
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({ token });
      }
    }
    // do we need to check if we are offline
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // remove first network message from queue
      const newOfflineQueue = [...this.state.offlineQueue];
      const networkRequest = newOfflineQueue[0];

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (
        apiRequestCallId &&
        responseJson &&
        apiRequestCallId === networkRequest?.messageId
      ) {
        //already checked if resonseJson
        if (responseJson && !responseJson.errors) {
          // if success
          newOfflineQueue.shift();
          this.updateOfflineMessageQueue(newOfflineQueue);
          // then send the next network message to runEngine as done in the NetInfo callback
          this.sendNextOfflineQueueMessage();
        }
      }

      //Check Error Response
      if (
        responseJson?.errors &&
        responseJson.errors.length > 0 &&
        responseJson.errors[0].token
      ) {
        this.showAlert("Session Expired", "Please Log in again.");
        this.updateOfflineMessageQueue([]);
        this.props.navigation.navigate("EmailAccountLogin");
      } else {
        if (this.state.isConnected) this.parseApiErrorResponse(responseJson);
      }

      if (this.state.isConnected) this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }
  // Customizable Area End

}
